import React from 'react';
import { Link } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo/seo";

import '../components/lists/asset-list.scss';
import Back from '../components/back/back';

const FareShare = () => {
  return (
    <Layout>
      <div className="details case-study hc-template">
        <SEO title="Fareshare Capstone" />
        <div className="fixed-nav desktop-only">
          <a href="/">Home</a>
        </div>
        <section className="wrapper-container">
          <div className="mobile-only">
            <a href="/">Home</a>
          </div>
          <h3>Fareshare</h3>

          <p>
            A capstone project for Springboard's UX Design Immersive.
            Acted as a UI/UX designer for a carpooling mobile application going from the discovery to the prototype phase.
            Utilized Google Docs, Survey, Sheets, FigJam, and Figma. 
          </p>
          <p><a rel="noopener noreferrer" href="https://www.figma.com/proto/HBW9JqfsuElibyebXUdDVV/Fareshare-for-Portfolio?page-id=1%3A2&node-id=1-409&p=f&viewport=498%2C354%2C0.07&t=DmSxPXcw8u08Tdke-1&scaling=scale-down&content-scaling=fixed" title="Prototype link" target="_blank">Prototype link</a></p>

        </section>
        <section className="wrapper-container">

          <div label="Understanding the User">
            <h3>1. Understanding the User</h3>
            <p>
              Carpooling’s popularity has waned in the United States. Aspects like coordination, payment, planning, and communication make carpooling a larger hassle than a solution.
            </p>
            <p>
              By completing secondary research and interviews, an understanding and empathy for the user was developed.
            </p>
            <p>
              Some artifacts that were produced include an affinity map, empathy maps, personas, and a post-interview survey analysis.
            </p>

            <ul className="asset-list">
              <li>
                <p className="text-center"><strong>Affinity Map</strong></p>

                <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1722171563/Edy_Hwang_-_Capstone_1_Affinity_Map_ulmccl.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1722171563/Edy_Hwang_-_Capstone_1_Affinity_Map_ulmccl.png" ></img></a>
                
                <p>
                Through the creation of an affinity map, post-interview quotes, comments, and data could be categorized into four main categories.</p>

                <p>
                  <strong>Decision</strong><br/>
                  Aspects related to the act of deciding to or choosing a carpool.
                </p>
                <p>
                  <strong>Route</strong><br/>
                  Logistics tied to both the carpool’s route and routes of alternate transportation options.
                </p>
                <p>
                  <strong>Responsibilities</strong><br/>
                  Questions and confusion over carpool details such as: who’s driving, who’s car, what route.
                </p>
                <p>
                  <strong>Trust</strong><br/>
                  Feelings and questions, towards carpooling with strangers and known acquaintances.
                </p>
              </li>
              <li>
                <p className="text-center">
                  <strong>Empathy Maps</strong>
                </p>
                <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1722170446/empathy-maps-np_urwbai.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1722170446/empathy-maps-np_urwbai.png" ></img></a>
                <p>
                  Empathy maps were created to represent two user archetypes from the interviews.
                </p>

                <p>
                  <strong>The Occupant</strong><br/>
                  An average carpool passenger or driver from key demographic groups including students and metropolitan workers.
                </p>
                <p>
                  <strong>The Planner</strong><br/>
                  The user who is more inclined to be the organizer of their peer group. This could include a personal inclination or professional responsibility.
                </p>

              </li>
              <li>
                <p className="text-center">
                  <strong>Personas</strong>
                </p>

                <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1722170447/personas-np_oxm5lm.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1722170447/personas-np_oxm5lm.png" ></img></a>

                <p>
                  As a method of further increasing empathy with the users, personas were created to resemble the two user archetypes.
                </p>

                <p>
                  For red route creation and testing, the goals and frustrations focused on and pertained to the generic find and create carpool experience more related to John.
                </p>
                <p>
                  Additionally, for MVP creation and testing, an emphasis on George would be beneficial for providing unique features such as private carpools and voting mechanisms for both driver and route selection.
                </p>
              </li>
              <li>
                <p className="text-center">
                  <strong>Likert Scale Analysis</strong>
                </p>

                <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1722170447/likert-scale-np_kykpw8.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1722170447/likert-scale-np_kykpw8.png" ></img></a>

                <p>
                  A post-interview survey was conducted utilizing the Likert Scale. The purpose of this survey was to validate information gathered from interviews while also helping to prioritize features. Some key insights also included:
                </p>

                <p>
                  <strong>Specificity by time</strong><br/>
                  During secondary research, it was possible to believe in ignoring the user selection of time - because of the lack of available carpools and the struggle to find ones by time.
                </p>
                <p>
                  <strong>Information on nearby carpools vs. show frequent locations</strong><br/>
                  All participants agreed on having information on nearby carpool location hubs; however, they diverged for showing frequent locations. The difference in responses showed a need for further research.
                </p>
                <p>
                  <strong>Friends vs. rating and reviews</strong><br/>
                  Rather than being friends, users leaned towards being professional and transparent.
                </p>
              </li>
            </ul>
          </div>
          <div label="Ideate and Implement">
            <h3>2. Ideate and Implement</h3>
            <p>Finding and creating a generic carpool were chosen for red route designs.</p>
            {/* <p><a className="link" rel="noopener noreferrer" href="https://www.figma.com/design/GaG1hWy2ODLqp2lRlTlhaI/Capstone-FareShare?node-id=6-2" title="Link to Wireframes" target="_blank">Link to Wireframes</a></p> */}

            <ul className="asset-list">
              <li>
                <p className="text-center"><strong>User Flows</strong></p>
                <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1722170447/userflow-find-np_igxkg4.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1722170447/userflow-find-np_igxkg4.png" ></img></a>
              </li>
              <li>
                <p className="text-center"><strong>Wireframes</strong></p>
                <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1722171935/Wireframe_Designs-np_vwijm3.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1722171935/Wireframe_Designs-np_vwijm3.png" ></img></a>
              </li>
              <li>
               <p className="text-center"><strong>Mood Board</strong></p>

                <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1722172084/Moodboard-np_qjxryw.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1722172084/Moodboard-np_qjxryw.png" ></img></a>

                <p>
                Green was chosen as the primary color because of its relation to transportation. Green road signs, the green light, green in the context of traffic, and the phrase “go for green” can all be considered.
                </p>
                <p>
                A sense of note-taking and ad-hoc highlighting was pursued to relate to logistics, memory retention, and impromptus.
                </p>
              </li>

              <li>
                <p className="text-center"><strong>Style Guide</strong></p>

                <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1722172192/Style_Guide_liml42.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1722172192/Style_Guide_liml42.png" ></img></a>

                <p>
                  A sans serif font was chosen to complement a flat design while Open Sans was chosen for its ease of implementation and popularity.
                </p>
                <p>
                Colors, buttons, and links were chosen to relate to paper interactions such as note-taking.
                </p>
              </li>
            </ul>
          </div>
          <div label="Test and Iterate">
            <h3>3. Test and Iterate</h3>
            <p>Upon completion of a prototype for the red routes, two rounds of usability testing were undergone. Major insights could be summarized into three categories: Information and details, access to actions, and contrast and compatibility.</p>
            {/* <p><a className="link" rel="noopener noreferrer" href="https://www.figma.com/design/GaG1hWy2ODLqp2lRlTlhaI/Capstone-FareShare?node-id=0-1" title="Link to Updated Designs" target="_blank">Link to Updated Designs</a></p> */}

            <ul className="asset-list">
              <li>
                <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1722171931/info-np_p5eeib.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1722171931/info-np_p5eeib.png" ></img></a>

                <p><strong>Information and Details</strong><br/>
                Users exhibited a need for more information as a carpooling service was something they were new to. Users also exhibited a need to confirm their previously inputted data.</p>
              </li>
              <li>
                <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1722171930/fixed-actions-np_mkqctq.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1722171930/fixed-actions-np_mkqctq.png" ></img></a>

                <p><strong>Access to Actions</strong><br/>
                Usability testing showed a user’s natural tendency to search a list prior to filtering - resulting in the filter or sort functions being hidden. When progressing through various steps, users also exhibited the need for actions to be made visible on the current screen.</p>
              </li>
              <li>
                <a className={`zoom-in`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1719320507/contrast_ap74xu.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1719320507/contrast_ap74xu.png" ></img></a>

                <p><strong>Contrast and Compatibility</strong><br/>
                In-person testing showed the need to accommodate varying screen devices through a darker shade of gray. Users also did not prioritize the top features due to the lack of contrast and attention given to the call-to-actions.</p>
              </li>
              <li>
                <p className="text-center"><strong>Updated Designs</strong></p>
                <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1722171930/Final_Designs-np_avnsht.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1722171930/Final_Designs-np_avnsht.png" ></img></a>
              </li>
            </ul>
          </div>
        </section>
        <div className="project-bottom">
          <Back></Back>
          <Link to="/gov-financial-app" title="To Next Project">
            Next Project
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default FareShare;